.dev-background{
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dev-back{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    width: 15vw;
    height: 8vh;
    border-radius: 5px;
    left: 5vh;
    top: 5vh;
    transition: background-color 0.5s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
    font-family: inter, sans-serif;
    color: #3a3a3a;
    animation-name: back-expand;
    animation-duration: 1s;
}

.dev-back:hover{
    background-color: rgba(255, 255, 255, 0.9);

}

@keyframes back-expand{
    from{width: 0;}
    to{width: 15vw}
}

.dev-about{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    width: 15vw;
    height: 80vh;
    border-radius: 5px;
    left: 5vh;
    top: 15vh;
    overflow: hidden;
    font-family: Inter, sans-serif;
    transition: background-color 0.5s, filter 0.5s;
    filter: grayscale(1);
    animation-name: about-expand;
    animation-duration: 1s;
}

.dev-about:hover{
    background-color: rgba(255, 255, 255, 0.9);
    filter: grayscale(0);
}

@keyframes about-expand{
    from{width: 0;}
    to{width: 15vw;}
}

.dev-name{
    /*background-color: blueviolet;*/
    position: relative;
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.35vw;
    user-select: none;
}

.dev-description{
    /*background-color: darkred;*/
    color: #3a3a3a;
    position: relative;
    width: 100%;
    height: 12%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    user-select: none;
}

.dev-picture{
    /*background-color: aqua;*/
    position: relative;
    background-image: url("../images/dev/man.png");
    background-size: cover;
    background-position: top;
    transition: filter 0.5s;
    width: 101%;
    height: 81%;
}

.dev-contact{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    height: 8vh;
    border-radius: 5px;
    left: calc(7vh + 15vw);
    right: calc(7vh + 5vw);
    top: 5vh;
    transition: background-color 0.5s;
    font-size: 1rem;
    font-family: inter, sans-serif;
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    filter: grayscale(1);
    animation-name: contact-expand;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}

@keyframes contact-expand{
    from {
        left: calc(7vh + 15vw);
        right: calc(85vw - 5vh);
    }
    to {
        left: calc(7vh + 15vw);
        right: calc(7vh + 5vw);
    }
}

.dev-contact:hover{
    background-color: rgba(255, 255, 255, 0.9);

}

.dev-contact-item{
    cursor: pointer;
    /*background-color: darkred;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.dev-contact-item:nth-child(1){
    cursor: default;
}

.dev-contact-item>div:nth-child(1) {
    color: #5d5d5d;
    letter-spacing: 5px;
}

@media only screen and (max-height: 400px) {
    .dev-contact-item>div:nth-child(1) {
        visibility: hidden;
        position: absolute;
    }
}

.dev-contact-item>div:nth-child(2) {
    color: #3a3a3a;
}

.dev-education, .dev-experience{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    width: 20vw;
    height: 38vh;
    border-radius: 5px;
    left: calc(7vh + 15vw);
    top: 15vh;
    overflow: hidden;
    font-family: Inter, sans-serif;
    transition: background-color 0.5s, filter 0.5s;
    animation-name: experience-expand;
    animation-duration: 1s;
    animation-delay: 0.75s;
    animation-fill-mode: both;
    user-select: none;
    filter: grayscale(1);
    padding-top: 3%;
}

.dev-education:hover, .dev-experience:hover{
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
    filter: grayscale(0.2);
}

.dev-education-title, .dev-experience-title{
    position: absolute;
    width: 100%;
    text-align: center;
    color: #5d5d5d;
    letter-spacing: 3px;
    top: 2%;
}

.dev-experience{
    height: 40vh;
    top: 55vh;
}

@keyframes experience-expand{
    from{width: 0;}
    to{width: 15vw;}
}

.dev-education-picture{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    bottom: 0;
    filter: opacity(0.3);
    background-image: url("../images/dev/dev-study.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.dev-education-item, .dev-experience-item{
    padding-left: 5%;
    width: 100%;
    height: 50%;
}

.dev-education-period, .dev-experience-period, .dev-experience-location{
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: #3a3a3a;
}

.dev-education-diploma, .dev-experience-position, .dev-experience-company{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: black;
}

.dev-education-institution{
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    color: #3a3a3a;
}

.dev-experience-picture{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    bottom: 0;
    filter: opacity(0.3);
    background-image: url("../images/dev/dev-work.png");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: bottom right;
}

.dev-PAC{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    height: 80vh;
    border-radius: 5px;
    left: calc(9vh + 30vw);
    right: calc(7vh + 5vw);
    top: 15vh;
    transition: background-color 0.5s;
    overflow: hidden;
    font-family: inter, sans-serif;
    animation-name: pac-expand;
    animation-duration: 1.5s;
    animation-delay: 1.5s;
    animation-fill-mode: both;
}

.dev-PAC:hover{
    background-color: rgba(255, 255, 255, 0.8);
}

@keyframes pac-expand {
    from{height: 0;}
    to{height: 80vh;}
}

.dev-PAC-nav-buttons{
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    width: 100%;
    height: 10%;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 1.2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.dev-PAC-nav-button-item{
    filter: grayscale(1);
}

#pac-projects{
    filter: grayscale(0);
}

.dev-PAC-nav-buttons>div{
    width: 30%;
    text-align: center;
    transition: font-size 0.3s;
    cursor: pointer;
}

.dev-PAC-nav-buttons>div:hover{
    font-size: 1.6rem;
}

.dev-PAC-title{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 15%;
    color: #5d5d5d;
    letter-spacing: 15px;
    font-size: 1vw;
    user-select: none;
}

.dev-PAC-holder{
    position: absolute;
    top: 20%;
    width: 100%;
    height: 80%;
    overflow: auto;
}

.dev-PAC-item{
    width: 100%;
    margin-bottom: 2%;
    margin-top: 1%;
    user-select: none;
    padding-left: 5%;
    padding-right: 5%;
    /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/
}

.dev-PAC-item-title{
    font-size: 1.2rem;
    color: #3a3a3a;
}

.dev-PAC-item-title:hover{
    color: black;
}

.dev-PAC-item-description{
    margin-top: 1%;
    margin-bottom: 2%;
    font-size: 0.8rem;
    color: #5d5d5d;
    padding-left: 1%;
    text-align: justify;
}

.dev-PAC-item-year{
    position: absolute;
    right: 6%;
    font-size: 0.8rem;
    color: #5d5d5d;
}

.dev-PAC-item>hr{
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.dev-PAC-link{
    font-size: 0.8rem;
}

.dev-CV{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    width: 5vw;
    height: 8vh;
    border-radius: 5px;
    right: 5vh;
    top: 5vh;
    transition: background-color 0.5s;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    overflow: hidden;
    user-select: none;
    font-family: inter, sans-serif;
    color: black;
    text-decoration: none;
    animation-name: cv-expand;
    animation-duration: 0.5s;
    animation-delay: 1.25s;
    animation-fill-mode: both;
}

@keyframes cv-expand{
    from {
        width: 0;
        right: calc(5vh + 5vw);
    }
    to {
        width: 5vw;
        right: 5vh;
    }
}


.dev-CV:hover{
    background-color: rgba(255, 255, 255, 0.9);
}

.dev-stack {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    padding-bottom: 2%;
    padding-top: 4%;
    width: 5vw;
    height: 80vh;
    border-radius: 5px;
    right: 5vh;
    top: 15vh;
    transition: background-color 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: 1.2vw;
    letter-spacing: 2px;
    overflow: hidden;
    user-select: none;
    font-family: inter, sans-serif;
    color: #3a3a3a;
    text-decoration: none;
    animation-name: stack-expand;
    animation-duration: 1s;
    animation-delay: 2s;
    animation-fill-mode: both;
}

.dev-stack:hover{
    background-color: rgba(255, 255, 255, 0.9);
}

@keyframes stack-expand {
    from{
        height: 0;
        padding-bottom: 0;
        padding-top: 0;
    }
    to{
        height: 80vh;
        padding-bottom: 2%;
        padding-top: 4%;
    }
}

.dev-stack>div{
    transform: rotate(-15deg);
}

.dev-stack>div:nth-child(1){
    transform: rotate(0deg);
    position: absolute;
    top: 2%;
    width: 100%;
    text-align: center;
    color: #5d5d5d;
    letter-spacing: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}

@media only screen and (max-width: 800px), screen and (max-height: 500px) {
    .dev-back{
        position: absolute;
        width: 90vw;
        height: 40px;
        left: 5vw;
        top: 30px;
        animation: expand-phone-90;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-fill-mode: both;
    }
    .dev-about{
        position: absolute;
        width: 40vw;
        height: 500px;
        left: 5vw;
        top: 100px;
        animation: expand-phone-40;
        animation-duration: 1s;
        animation-delay: 0s;
        animation-fill-mode: both;
    }
    .dev-name{
        font-size: 1.2rem;
        display: block;
        text-align: center;
        margin-top: 5%;
    }
    .dev-contact{
        position: absolute;
        width: 90vw;
        height: 150px;
        left: 5vw;
        top: 630px;
        animation: expand-phone-90;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-fill-mode: both;
        flex-direction: column;
    }
    .dev-education{
        position: absolute;
        width: 45vw;
        height: 240px;
        left: 50vw;
        top: 100px;
        animation: expand-phone-45;
        animation-duration: 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
        padding-top: 7%;
    }
    .dev-experience{
        position: absolute;
        width: 45vw;
        height: 240px;
        left: 50vw;
        top: 360px;
        padding-top: 7%;
        animation: expand-phone-45;
        animation-duration: 1s;
        animation-delay: 1s;
        animation-fill-mode: both;
    }
    .dev-CV{
        position: absolute;
        width: 90vw;
        height: 40px;
        left: 5vw;
        top: 810px;
        animation: expand-phone-90;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-fill-mode: both;
    }
    .dev-stack{
        position: absolute;
        width: 90vw;
        height: 200px;
        left: 5vw;
        top: 880px;
        animation: expand-phone-90;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-fill-mode: both;
        font-size: 1rem;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
        padding: 10% 7% 7% 7%;
    }
    .dev-stack>div{
        display: flex;
        transform: rotate(0);
    }
    .dev-stack>div::before{
        content: "|"
    }
    .dev-stack>div::after{
        content: "|"
    }
    .dev-stack>div:nth-child(1){
        font-size: 0.8rem;
        margin-top: 2%;
    }
    .dev-PAC{
        position: absolute;
        width: 90vw;
        height: 500px;
        left: 5vw;
        top: 1110px;
        animation: expand-phone-90;
        animation-duration: 2s;
        animation-delay: 0s;
        animation-fill-mode: both;
    }
    .dev-PAC-title{
        font-size: 0.8rem;
    }
    .dev-dummy-space{
        position: absolute;
        height: 30px;
        width: 90vw;
        left: 5vw;
        top: 1610px;
        animation: none;
    }
}


@keyframes expand-phone-40{
    from{width: 0;}
    to{width: 40vw}
}

@keyframes expand-phone-45{
    from{width: 0;}
    to{width: 45vw}
}

@keyframes expand-phone-90{
    from{width: 0;}
    to{width: 90vw}
}
