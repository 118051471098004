.art-background{
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.art-back{
    position: absolute;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    height: 50px;
    width: 15vw;
    left: 0;
    top: 3vh;
    font-family: inter, sans-serif;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    user-select: none;
    cursor: pointer;
    transition: filter 0.5s;
    filter: opacity(0.6);
    z-index: 999;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}

.art-back:hover{
    filter: opacity(1);
}

.art-placeholder{
    position: absolute;
    font-family: inter, sans-serif;
    height: 400px;
    top: 160px;
    width: 80vw;
    left: 10vw;
    text-align: center;
    color: rgb(244, 233, 255);
    letter-spacing: 2px;
    user-select: none;
    font-size: 1.2rem;
}

.art-placeholder > div{
    display: inline;
}

.art-placeholder > a{
    color: rgb(244, 233, 255);
}

.art-mice{
    /*border: 2px solid white;*/
    position: absolute;
    width: 400px;
    height: 300px;
    left: calc(50vw - 200px);
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../images/art/mice.png");
    filter: saturate(0) opacity(0.3);
    z-index: 0;
}

@media only screen and (max-width: 500px) {
    .art-back{
        width: 50vw;
    }

    .art-mice {
        position: absolute;
        width: 200px;
        height: 150px;
        left: calc(50vw - 100px);
    }
}