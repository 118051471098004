.diy-background{
    position: fixed;
    background-image: url("../images/diy/diybackground.jpg");
    background-size: cover;
    background-position: center;
    filter: brightness(0.4);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.diy-back{
    position: absolute;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    height: 50px;
    width: 15vw;
    left: 0;
    top: 20px;
    font-family: inter, sans-serif;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    user-select: none;
    cursor: pointer;
    transition: filter 0.5s;
    filter: opacity(0.7);
    z-index: 999;
}

.diy-back:hover{
    filter: opacity(1);
}

.diy-placeholder{
    width: 450px;
    height: 150px;
    left: min(calc(50vw - 225px), 500px);
    top: calc(50vh - 100px);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    font-family: inter, sans-serif;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    box-shadow: 1px 1px 5px white;
    z-index: 999;
}

.diy-placeholder-inside{
    display: inline;
}

.diy-placeholder-inside > div{
    display: inline;
}

.diy-drill{
    position: fixed;
    width: 600px;
    height: 400px;
    background-image: url("../images/diy/drill.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    z-index: 0;
    left: min(calc(50vw - 725px), -10px);
    top: calc(50vh - 100px);
    transition: transform 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}

@media only screen and (max-width: 900px) {
    .diy-back{
        width: 50vw;
    }
    .diy-placeholder{
        width: 280px;
        height: 70px;
        font-size: 12px;
        left: calc(50vw - 140px);
        bottom: min(500px, 70vh);
        top: auto;
    }
    .diy-drill {
        bottom: calc(min(500px, 70vh) - 420px);
        top: auto;
        left: calc(50vw - 400px);
        transform: scaleX(-1) rotate(-90deg);
    }
}