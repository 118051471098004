.write-background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: url("../images/write/write-glass.jpg");
    background-size: cover;
    background-color: black;
    z-index: -1;
    filter: saturate(0.3) brightness(0.3);
}

.write-back{
    position: absolute;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    height: 50px;
    width: 15vw;
    left: 0;
    top: 3vh;
    font-family: inter, sans-serif;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    user-select: none;
    cursor: pointer;
    transition: filter 0.5s;
    filter: opacity(0.6);
    z-index: 999;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}

.write-back:hover{
    filter: opacity(1);
}

.write-placeholder{
    position: fixed;
    /*border: 2px solid white;*/
    z-index: 999;
    width: 50vw;
    height: 50vh;
    left: 50vw;
    top: 25vh;
    color: white;
    font-family: Fuggles, serif;
    font-size: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5%;
}

@media only screen and (max-width: 750px) {
    .write-back{
        width: 50vw;
    }
    .write-placeholder{
        left: 25vw;
        padding-left: 0;
    }
}

@media only screen and (max-width: 350px) {
    .write-placeholder{
        left: 5vw;
        width: 100vw;
    }
}