* {
    box-sizing: border-box;
    --satellite-offset: max(130px, 30vh);
}

@font-face {
    src: url("../fonts/Inter-VariableFont_slnt,wght.ttf");
    font-family: Inter;
}

.home-background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-color: black;
    filter: brightness(0.5);
}

.center-flash {
    position: fixed;
    /*visibility: hidden;*/
    width: 120px;
    height: 120px;
    top: calc(40vh - 60px);
    left: calc(50vw - 60px);
    background-size: cover;
    background-position: center;
    background-image: url("../images/logotbf.png");
    filter: opacity(0.9);
    z-index: 999;
    margin-bottom: 0;
    margin-right: 0;
    animation-name: center-fade;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: both;
}

@keyframes center-fade {
    from {
        filter: opacity(1);
    }
    to {
        filter: opacity(0.9);
    }
}

.satellites > div{
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    border-radius: 50%;

    --satellite-alpha: 0.65;
    background-color: rgba(255, 255, 255, var(--satellite-alpha));
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, var(--satellite-alpha));

    --satellite-size: min(max(100px, 7vw), 25vh);
    height: var(--satellite-size);
    width: var(--satellite-size);
    top: calc(40vh - var(--satellite-size)/2);
    left: calc(50vw - var(--satellite-size)/2);

    color: #1f1f1f;
    font-family: inter, sans-serif;
    /*font-size: 2rem;*/
    /*padding-left: 1rem;*/
    /*letter-spacing: 1rem;*/

    /*filter: opacity(0.1);*/

    display: flex;
    align-items: center;
    justify-content: center;

    user-select: none;
    cursor: pointer;

    transition: background-color 1s, box-shadow 1s, transform 1s, height 1s, width 1s, top 1s, left 1s;
}

@media only screen and (max-width: 600px) {
    .satellites > div{
        --satellite-offset: max(100px, 15vh);
        --small-satellite-size: max(60px, 6vw);
        height: var(--small-satellite-size);
        width: var(--small-satellite-size);
        top: calc(40vh - var(--small-satellite-size)/2);
        left: calc(50vw - var(--small-satellite-size)/2);
    }
    .center-flash {
        width: 80px;
        height: 80px;
        top: calc(40vh - 40px);
        left: calc(50vw - 40px);
    }
}

.satellites > div:hover{
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, 1);
}

.satellites > div:nth-child(1) {
    transform: rotate(-64.26deg) translate(var(--satellite-offset)) rotate(64.26deg);
    animation-name: satellite1-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.satellites > div:nth-child(2) {
    transform: rotate(-12.84deg) translate(var(--satellite-offset)) rotate(12.84deg);
    animation-name: satellite2-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.satellites > div:nth-child(3) {
    transform: rotate(38.58deg) translate(var(--satellite-offset)) rotate(-38.58deg);
    animation-name: satellite3-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.satellites > div:nth-child(4) {
    transform: rotate(90deg) translate(var(--satellite-offset)) rotate(-90deg);
    animation-name: satellite4-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.satellites > div:nth-child(5) {
    transform: rotate(141.42deg) translate(var(--satellite-offset)) rotate(-141.42deg);
    animation-name: satellite5-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.satellites > div:nth-child(6) {
    transform: rotate(192.84deg) translate(var(--satellite-offset)) rotate(-192.84deg);
    animation-name: satellite6-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.satellites > div:nth-child(7) {
    transform: rotate(244.26deg) translate(var(--satellite-offset)) rotate(-244.26deg);
    animation-name: satellite7-move;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

@keyframes satellite1-move {
    from {
        transform: rotate(-180.24deg) translate(0px) rotate(64.26deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(-64.26deg) translate(var(--satellite-offset)) rotate(64.26deg);
        --satellite-alpha: 0.65;
    }
}

@keyframes satellite2-move {
    from {
        transform: rotate(-132.84deg) translate(0px) rotate(12.84deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(-12.84deg) translate(var(--satellite-offset)) rotate(12.84deg);
        --satellite-alpha: 0.65;
    }
}

@keyframes satellite3-move {
    from {
        transform: rotate(-81.42deg) translate(0px) rotate(-38.58deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(38.58deg) translate(var(--satellite-offset)) rotate(-38.58deg);
        --satellite-alpha: 0.65;
    }
}

@keyframes satellite4-move {
    from {
        transform: rotate(-30deg) translate(0px) rotate(-90deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(90deg) translate(var(--satellite-offset)) rotate(-90deg);
        --satellite-alpha: 0.65;
    }
}

@keyframes satellite5-move {
    from {
        transform: rotate(21.42deg) translate(0px) rotate(-141.42deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(141.42deg) translate(var(--satellite-offset)) rotate(-141.42deg);
        --satellite-alpha: 0.65;
    }
}

@keyframes satellite6-move {
    from {
        transform: rotate(72.84deg) translate(0px) rotate(-192.84deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(192.84deg) translate(var(--satellite-offset)) rotate(-192.84deg);
        --satellite-alpha: 0.65;
    }
}

@keyframes satellite7-move {
    from {
        transform: rotate(124.26deg) translate(0px) rotate(-244.26deg);
        background-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0);
        color: transparent;
    }
    to {
        transform: rotate(244.26deg) translate(var(--satellite-offset)) rotate(-244.26deg);
        --satellite-alpha: 0.65;
    }
}

.console-bar{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);

    --console-width: max(260px, 30vw);
    width: var(--console-width);
    height: 2.5rem;
    left: calc(50vw - var(--console-width)/2);
    bottom: 10vh;

    border-radius: 2px;
    border: 2px solid black;
    color: rgb(128, 128, 128);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.7rem;
    padding-left: 7px;
    font-family: inter, sans-serif;
    letter-spacing: 5px;
}

.console-bar::after {
    content: "_";
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.satellites>div>div{
    background-size: cover;
    background-position: center;
    width: 80%;
    height: 80%;
    image-rendering: crisp-edges;
}

.home-icon-AN{
    background-image: url('../images/icons/ICON_AN.png');
}

.home-icon-CK{
    background-image: url('../images/icons/ICON_CK.png');
}

.home-icon-WR{
    background-image: url('../images/icons/ICON_WR.png');
}

.home-icon-AB{
    background-image: url('../images/icons/ICON_AB.png');
}

.home-icon-MS{
    background-image: url('../images/icons/ICON_MS.png');
}

.home-icon-DY{
    background-image: url('../images/icons/ICON_DY.png');
}

.home-icon-CS{
    background-image: url('../images/icons/ICON_CS.png');
}
