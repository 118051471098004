.more-background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    background-image: url("../images/more/more-bg.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    filter: blur(4px);
}

.more-back{
    position: absolute;
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    height: 50px;
    width: 15vw;
    left: 0;
    top: 3vh;
    font-family: inter, sans-serif;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    user-select: none;
    cursor: pointer;
    transition: filter 0.5s;
    filter: opacity(0.6);
    z-index: 999;
    color: white;
}

.more-back:hover{
    filter: opacity(1);
}

.more-item{
    position: absolute;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 5px;
    padding-right: 5px;
    height: 23px;
    border: 1px solid #000000;
    border-radius: 5px;
    display: flex;
    align-items: center;
    user-select: none;
    justify-content: center;
    cursor: crosshair;
    transition: left 0.5s, top 0.5s, rotate 0.5s;
}

@media only screen and (max-width: 750px) {
    .more-back{
        width: 50vw;
    }
    .more-placeholder{
        left: 25vw;
        padding-left: 0;
    }
}