@font-face {
    src: url("../fonts/Fuggles-Regular.ttf");
    font-family: Fuggles;
    size-adjust: 170%;
    font-display: swap;
}

@font-face {
    src: url("../fonts/Lora.ttf");
    font-family: Lora;
}

.cook-background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: url("../images/cook/cook-background.jpg");
    background-size: cover;
    background-position: center;
    filter: grayscale(1) brightness(20%);
}

.cook-back{
    position: absolute;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    height: 50px;
    width: 15vw;
    left: 0;
    top: 3vh;
    font-family: inter, sans-serif;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    user-select: none;
    cursor: pointer;
    transition: filter 0.5s;
    filter: opacity(0.6);
    animation-name: fade-in-opacity;
    animation-duration: 1s;
    z-index: 999;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}

.cook-back:hover{
    filter: opacity(1);
}

.cook-country{
    height: 30px;
    width: 45px;
    position: absolute;
    top: calc(3vh + 10px);
    right: 2vw;
    display: flex;
    flex-direction: row;
    filter: opacity(0.7);
    transition: filter 0.5s;
    z-index: 9999;
    animation-name: fade-in-opacity;
    animation-duration: 1s;
}

.cook-country:hover{
    filter: opacity(1);
}

.cook-flag-blue{
    height: 100%;
    width: 33.3%;
    left: 0;
    top: 0;
    background-color: #002B7F;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.cook-flag-yellow{
    height: 100%;
    width: 33.3%;
    left: 33.3%;
    top: 0;
    background-color: #FCD116;
}

.cook-flag-red{
    height: 100%;
    width: 33.3%;
    left: 66.6%;
    top: 0;
    background-color: #CE1126;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.cook-flag-hint {
    display: none;
    position: absolute;
    height: 30px;
    top: -10%;
    width: 100px;
    right: 120%;
    color: rgba(255, 255, 255, 0.8);
    text-align: right;
    font-family: inter, sans-serif;
}

.cook-country:hover .cook-flag-hint{
    display: block;
}

.cook-title{
    position: absolute;
    font-family: Fuggles, serif;
    color: rgba(255, 255, 255, 0.8);
    font-size: 2.5rem;
    width: 90vw;
    height: 100px;
    text-align: center;
    top: 15px;
    left: 5vw;
    transition: color 1s;
    user-select: none;
    animation-name: fade-in-opacity;
    animation-duration: 3s;
    animation-delay: 0.2s;
    animation-fill-mode: both;
}

.cook-title:hover{
    color: rgba(255, 255, 255, 1);
}

.cook-name{
    position: absolute;
    width: 80vw;
    left: 10vw;
    height: 50px;
    color: rgba(255, 255, 255, 0.8);
    font-family: inter, sans-serif;
    letter-spacing: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 105vh;
    font-size: 0.8rem;
    text-align: center;
}

.cook-intro-image{
    position: absolute;
    background-image: url("../images/cook/knife.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 20vh;
    left: calc(50vw - 10vh);
    height: calc(100vh - 100px);
    top: 100px;
    filter: grayscale(1);
    transition: filter 1s;
    animation-name: knife-go-in-100;
    animation-duration: 1.5s;
    animation-delay: 1s;
    animation-fill-mode: both;
}

.cook-intro-image:hover{
    filter: grayscale(0);
}

.cook-image-paprika{
    position: absolute;
    background-image: url("../images/cook/intro.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 20vw;
    right: 5vw;
    height: 40vw;
    bottom: -14vw;
    filter: grayscale(0.5);
    transition: filter 1s;
    animation-name: fade-in-opacity;
    animation-duration: 4s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
}

.cook-image-pan{
    position: absolute;
    background-image: url("../images/cook/pan.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 20vw;
    left: 5vw;
    height: 30vw;
    bottom: -7vw;
    filter: grayscale(0.3);
    transition: filter 1s;
    animation-name: fade-in-opacity;
    animation-duration: 4s;
    visibility: hidden;
}

.cook-image-paprika:hover {
    filter: grayscale(0);
}

.cook-image-pan:hover {
    filter: grayscale(0);
}

.cook-plate{
    position: relative;
    /*border: 1px solid white;*/
    width: 90vw;
    height: 450px;
    left: calc(5vw - 10px);
    top: max(700px, 120vh);
    margin-bottom: 140px;
    /*border: 1px solid white;*/
}

.cook-plate-picture{
    position: absolute;
    object-fit: contain;
    width: 300px;
    height: 300px;
    top: 0;
    left: calc(50% - 150px);
    z-index: 999;
    transition: transform 1s, opacity 1s, border-radius 1s;
    /*border: 1px solid white;*/
}

.cook-plate-picture:hover{
    transform: scale(1.5);
    opacity: 0.7;
    border-radius: 5px;
}

.cook-plate-title{
    position: absolute;
    color: rgba(255, 255, 255, 0.9);
    width: 70%;
    height: 20%;
    bottom: 10%;
    left: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    letter-spacing: 2px;
    font-family: Lora, serif;
    text-align: center;
}

.cook-plate-contains-item{
    position: absolute;
    color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    top: 70px;
    left: 30px;
    transform: rotate(-18deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: inter, sans-serif;
    font-size: 0.8rem;
    user-select: none;
    transition: height 0.5s, padding 0.5s;
    max-width: 110px;
    white-space: nowrap;
}

.cook-plate-contains-item:hover{
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.cook-plate-decoration{
    position: absolute;
    background-image: url("../images/cook/decoration-1.png");
    background-position: center;
    background-size: 400px auto;
    background-repeat: no-repeat;
    /*border: 1px solid gray;*/
    width: 100%;
    height: 10%;
    bottom: 0;
    filter: invert(0.5);
}

.cook-development{
    position: relative;
    width: 90vw;
    height: 200px;
    left: calc(5vw - 10px);
    top: max(700px, 120vh);
    margin-bottom: 50px;
    font-family: inter, sans-serif;
    color: white;
    text-align: center;
}

.cook-dummy-space{
    position: relative;
    width: 90vw;
    height: 200px;
    left: calc(5vw - 10px);
    top: max(700px, 120vh);
    margin-bottom: 50px;
}

@keyframes fade-in-opacity{
    from{opacity: 0;}
    to{opacity: 1;}
}

@keyframes knife-go-in-100{
    from{top: 0; opacity: 0;}
    to{top: 100px; opacity: 1}
}

@keyframes knife-go-in-150{
    from{top: 0; opacity: 0;}
    to{top: 150px; opacity: 1}
}

@keyframes knife-go-in-200{
    from{top: 0; opacity: 0;}
    to{top: 200px; opacity: 1}
}

@media only screen and (max-width: 700px){
    .cook-back{
        width: 35vw;
    }
    .cook-country {
        right: 5vw;
    }
    .cook-intro-image{
        top: 150px;
        height: calc(100vh - 150px);
        animation-name: knife-go-in-150;
        animation-duration: 1.5s;
    }
    .cook-title{
        top: 100px;
        font-size: 1.8rem;
    }
    .cook-name{
        font-size: 0.7rem;
    }
    .cook-plate{
        height: 350px;
        margin-bottom: 90px;
    }
    .cook-plate-picture{
        width: 200px;
        height: 200px;
        left: calc(50% - 100px);
    }
    .cook-plate-title{
        bottom: 15%;
        font-size: 1rem;
    }
    .cook-image-paprika{
        visibility: hidden;
    }
    .cook-image-pan{
        visibility: hidden;
    }
    .cook-plate-contains{
        visibility: hidden;
    }
    .cook-plate-picture:hover{
        transform: scale(1.35);
    }
}

@media only screen and (max-width: 400px) {
    .cook-intro-image{
        top: 200px;
        height: calc(100vh - 200px);
        animation-name: knife-go-in-200;
        animation-duration: 1.5s;
    }
    .cook-name{
        width: 100vw;
        left: 0;
        font-size: 0.6rem;
    }
}