.about-background{
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.about-back{
    position: absolute;
    background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    height: 50px;
    width: 15vw;
    left: 0;
    top: 20px;
    font-family: inter, sans-serif;
    display: flex;
    align-items: center;
    padding-left: 1vw;
    user-select: none;
    cursor: pointer;
    transition: filter 0.5s;
    filter: opacity(0.6);
    z-index: 999;
}

.about-back:hover{
    filter: opacity(1);
}

.about-action{
    position: absolute;
    width: 30vw;
    height: 50px;
    left: 35vw;
    top: 20px;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: inter, sans-serif;
    letter-spacing: 6px;
    font-size: 1rem;
    transition: color 0.2s;
    cursor: pointer;
}

.about-action:hover{
    color: rgba(255, 255, 255, 1);
}

.about-picture{
    position: absolute;
    height: 400px;
    width: 400px;
    left: calc(50vw - 200px);
    top: calc(50vh - 200px);
    filter: opacity(0.95);
    transition: filter 0.5s;
    animation-name: turn-color;
    animation-duration: 2s;
}

.about-picture:hover{
    filter: opacity(1);
}

.about-name{
    position: absolute;
    height: 50px;
    top: calc(50vh + 220px);
    width: 90vw;
    left: 5vw;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    font-family: inter, sans-serif;
    color: white;
    font-size: 1.8rem;
    letter-spacing: 3px;
}

.about-title{
    position: absolute;
    height: 50px;
    top: calc(50vh + 270px);
    width: 90vw;
    left: 5vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    font-family: inter, sans-serif;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    letter-spacing: 5px;
}

.about-left-container{
    position: absolute;
    width: calc(50vw - 200px);
    height: 400px;
    left: 0;
    top: calc(50vh - 200px);
    display: flex;
    align-items: center;
    padding-left: 20px;
    flex-direction: column;
    justify-content: space-evenly;
    animation-name: turn-color;
    animation-duration: 2s;
}

.about-languages{
    height: 180px;
    width: 150px;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    filter: opacity(0.7);
    transition: filter 0.5s;
    user-select: none;
}

.about-languages:hover{
    filter: opacity(1);
}

.about-language-item{
    position: relative;
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-family: inter, sans-serif;
    font-size: 0.8rem;
}

.about-language-icon{
    position: relative;
    height: 40%;
    width: 20%;
    margin: 5%;
    background-color: black;
}

#about-flag-ro{
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Romania.svg/255px-Flag_of_Romania.svg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#about-flag-us{
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/800px-Flag_of_the_United_Kingdom_%281-2%29.svg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#about-flag-fr{
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Flag_of_France_%281794%E2%80%931815%2C_1830%E2%80%931974%29.svg/1280px-Flag_of_France_%281794%E2%80%931815%2C_1830%E2%80%931974%29.svg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#about-flag-es{
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/8/89/Bandera_de_Espa%C3%B1a.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.about-sections{
    height: 160px;
    width: 150px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    color: white;
    transition: filter 0.5s;
    user-select: none;
    text-align: center;
    font-family: inter, sans-serif;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding-left: 5px;
}

.about-section-item{
    filter: opacity(0.7);
    cursor: pointer;
}

.about-section-item:hover{
    filter: opacity(1);
}

.about-section-item:hover:before{
    content:"_";
}

.about-right-container{
    position: absolute;
    width: calc(50vw - 200px);
    height: 360px;
    left: calc(50vw + 200px);
    top: calc(50vh - 200px);
    display: flex;
    align-items: center;
    padding-right: 20px;
    flex-direction: column;
    justify-content: space-evenly;
}

.about-data{
    height: 400px;
    width: 150px;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 5px;
    transition: filter 0.5s;
    user-select: none;
    overflow: auto;
    font-family: inter, sans-serif;
    font-size: 0.9rem;
    color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.about-data-item{
    width: 30px;
    height: 30px;
    /*border: 1px solid white;*/
    cursor: pointer;
    transition: transform 1s, filter 1s;
    filter: opacity(0.7);
}

.about-data-item:hover{
    transform: scale(1.5);
    filter: opacity(1);
}

#about-data-location{
    background-image: url("../images/about/about-data-location.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#about-data-email{
    background-image: url("../images/about/about-data-email.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#about-data-github{
    background-image: url("../images/about/about-data-github.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#about-data-linkedin{
    background-image: url("../images/about/about-data-linkedin.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

#about-data-overflow{
    background-image: url("../images/about/about-data-overflow.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.about-copyright{
    position: fixed;
    width: 90vw;
    height: 40px;
    bottom: 0;
    left: 5vw;
    text-align: center;
    font-family: inter, sans-serif;
    color: rgba(255, 255, 255, 0.5);
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-copyright > a{
    color: rgba(255, 255, 255, 0.5);
}

@keyframes turn-color{
    from{filter: saturate(0);}
    to{filter: saturate(1);}
}

@media only screen and (max-width: 850px), screen and (max-height: 650px) {
    .about-back{
        width: 50vw;
    }

    .about-action{
        top: 100px;
        width: 90vw;
        left: 5vw;
    }

    .about-picture{
        top: 180px;
        width: 260px;
        height: 260px;
        left: calc(50vw - 130px);
    }

    .about-name{
        top: 450px;
        font-size: 1.2rem;
        letter-spacing: 5px;
    }

    .about-title{
        top: 500px;
        font-size: 0.5rem;
        color: rgba(255, 255, 255, 0.75);
    }

    .about-left-container{
        top: 670px;
        width: 90vw;
        left: 5vw;
        display: block;
        height: 300px;
        padding-left: 0;
    }

    .about-languages{
        position: absolute;
        width: 40vw;
        height: 200px;
    }

    .about-sections{
        position: absolute;
        width: 40vw;
        left: 50vw;
        height: 200px;
        padding-left: 15px;
    }

    .about-right-container{
        width: 90vw;
        height: 100px;
        left: 5vw;
        padding-right: 0;
        top: 540px;
    }

    .about-data{
        width: 100%;
        height: 100px;
        flex-direction: row;
        overflow: hidden;
    }

    .about-data-item{
        width: 30px;
        height: 30px;
    }

    .about-copyright{
        position: absolute;
        top: 900px;
    }
}